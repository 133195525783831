import { utils } from "@/api/http-common";
import Axios from "axios";

export class Txn_V2Api {
	static async fetchTransactions(paging: any) {
		return Axios.get(utils.generateUrl(`v2/transactions?status=reviewing_deposit&status=reviewing_withdrawal`), {
			params: {
				page_number: paging.page,
				page_size: paging.itemsPerPage
			},
			headers: await utils.generateHeader()
		});
	}

	static async fetchTransactionsToAssign(paging: any) {
		return Axios.get(utils.generateUrl(`v2/transactions/banking-candidate?candidate=true`), {
			params: {
				page_number: paging.page,
				page_size: paging.itemsPerPage
			},
			headers: await utils.generateHeader()
		});
	}

	static async getOrganisationByCode(code: string) {
		return Axios.get(utils.generateUrl(`organisations/code/${code}`), {
			headers: await utils.generateHeader()
		});
	}

	static async setTxnToOrg(txnId: string, data: any) {
		return Axios.put(utils.generateUrl(`v2/transactions/banking-candidate/${txnId}/assign`), data, {
			headers: await utils.generateHeader()
		});
	}

	static async submitTransaction(txn: any) {
		return Axios.put(utils.generateUrl(`v2/transactions/review`), txn, {
			headers: await utils.generateHeader()
		});
	}
}
