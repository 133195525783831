import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { Txn_V2Api } from "@/api/txns_v2-api";
import { TransactionsReviewState } from "@/store/transactions/types";

export const actions: ActionTree<TransactionsReviewState, RootState> = {
	async getTransactions({ commit, state }) {
		try {
			commit("loading");
			const response = await Txn_V2Api.fetchTransactions(state.pagination);
			let results = response.data.results;

			// create unique identifier
			results.forEach((result: any, index: number) => {
				result.index = index;
			});

			commit("setTransactions", response.data.results);
			commit("setTotal", response.data.count);
		} catch (error) {
			commit("error");
		}
	},

	async getTransactionsToAssign({ commit, state }) {
		try {
			commit("loading");
			const response = await Txn_V2Api.fetchTransactionsToAssign(state.pagination);
			commit("setTxnsToAssign", response.data.results);
			commit("setTotal", response.data.count);
			commit("setSearchOrg");
		} catch (error) {
			commit("error");
		}
	},

	async getOrganisationByCode({ commit, dispatch }, code: string) {
		try {
			commit("loading");
			const response = await Txn_V2Api.getOrganisationByCode(code);
			commit("setSearchOrg", response.data);
			return true;
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
			return false;
		}
	},

	async setTxnToOrg({ commit, dispatch }, data: any) {
		try {
			commit("loading");
			const txnId = data.txnId;
			const body = { assignToOrgId: data.orgId, bankingMethodKey: data.bankingMethodKey };
			await Txn_V2Api.setTxnToOrg(txnId, body);
			dispatch("getTransactionsToAssign");
		} catch (error) {
			commit("error");
		}
	},

	async submitReview({ dispatch }, txn: any) {
		try {
			await Txn_V2Api.submitTransaction(txn);
			dispatch("getTransactions");
			dispatch("notifications/success", "Review submitted", { root: true });
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	paginate({ commit }, pagination) {
		commit("paginate", pagination);
	}
};
