import { Module } from "vuex";
import { getters } from "@/store/transactions/getters";
import { actions } from "@/store/transactions/actions";
import { mutations } from "@/store/transactions/mutations";
import { RootState } from "@/store/types";
import { TransactionsReviewState, Organisation } from "@/store/transactions/types";
import { statusFactory } from "../utils";

export const state: TransactionsReviewState = {
	transactions: [],
	txnToAssign: [],
	searchedOrg: [],
	totalItems: 0,
	status: statusFactory(),
	pagination: {
		page: 1, // Page Start Index
		itemsPerPage: 25,
		sortBy: "",
		totalItems: 0,
		descending: false,
		filters: {}
	}
};

const namespaced: boolean = true;

export const transactions: Module<TransactionsReviewState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};
