import { TransactionsReviewState } from "@/store/transactions/types";
import { MutationTree } from "vuex";

export const mutations: MutationTree<TransactionsReviewState> = {
	setTransactions(state, payload) {
		state.transactions = payload;
		state.status.error = false;
		state.status.loading = false;
		state.status.success = false;
	},
	setTxnsToAssign(state, payload) {
		state.txnToAssign = payload;
		state.status.error = false;
		state.status.loading = false;
		state.status.success = false;
	},
	setSearchOrg(state, payload = null) {
		state.searchedOrg = payload;
		state.status.error = false;
		state.status.loading = false;
		state.status.success = false;
	},
	paginate(state, payload) {
		state.pagination = payload;
	},
	setTotal(state, count: number) {
		state.totalItems = count;
	},
	loading(state) {
		state.status.error = false;
		state.status.loading = true;
		state.status.success = false;
	},
	success(state, payload?) {
		state.status.error = false;
		state.status.loading = false;
		state.status.success = payload || true;
	},
	error(state, payload?) {
		state.status.error = payload || true;
		state.status.loading = false;
		state.status.success = false;
	}
};
